var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-8" },
        [
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "판정 설비별 목록",
                tableId: "equip",
                columns: _vm.grid.columns,
                data: _vm.grid.data,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                isExcelDown: false,
                isFullScreen: false,
              },
              on: { "table-data-change": _vm.tableDataChange },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: {
                              isSubmit: _vm.isSave1,
                              url: _vm.saveUrl,
                              param: _vm.grid.saveData,
                              mappingType: "PUT",
                              label: "저장",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveEquipChecklist,
                              btnCallback: _vm.saveEquipChecklistCallback,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-4" },
        [
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "판정 정비·운전 목록",
                tableId: "equip",
                columns: _vm.grid2.columns,
                data: _vm.grid2.data,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                isExcelDown: false,
                isFullScreen: false,
              },
              on: { "table-data-change": _vm.tableDataChange },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: {
                              isSubmit: _vm.isSave2,
                              url: _vm.saveUrl,
                              param: _vm.grid2.saveData,
                              mappingType: "PUT",
                              label: "저장",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveCheckDriveChecklist,
                              btnCallback: _vm.saveCheckDriveChecklistCallback,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }