<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8">
        <c-table
          ref="table"
          title="판정 설비별 목록"
          tableId="equip"
          :columns="grid.columns"
          :data="grid.data"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :isExcelDown="false"
          :isFullScreen="false"
          @table-data-change="tableDataChange"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn 
                v-if="editable" 
                :isSubmit="isSave1"
                :url="saveUrl"
                :param="grid.saveData"
                mappingType="PUT"
                label="저장" 
                icon="save" 
                @beforeAction="saveEquipChecklist"
                @btnCallback="saveEquipChecklistCallback"/>
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
        <c-table
          ref="table"
          title="판정 정비·운전 목록"
          tableId="equip"
          :columns="grid2.columns"
          :data="grid2.data"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :isExcelDown="false"
          :isFullScreen="false"
          @table-data-change="tableDataChange"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
                  <c-btn 
                v-if="editable" 
                :isSubmit="isSave2"
                :url="saveUrl"
                :param="grid2.saveData"
                mappingType="PUT"
                label="저장" 
                icon="save" 
                @beforeAction="saveCheckDriveChecklist"
                @btnCallback="saveCheckDriveChecklistCallback"/>
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'determination-checklist',
  data() {
    return {
      searchParam: {
        // plantCd: null,
        // useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: 'mocCheckItemName',
            field: 'mocCheckItemName',
            label: '변경요소',
            align: 'center',
            sortable: true,
            style: 'width:130px',
          },
        ],
        data: [],
        saveData: [],
        itemCds: [],
      },
      grid2: {
        columns: [
          {
            name: 'mocCheckItemName',
            field: 'mocCheckItemName',
            label: '변경요소',
            align: 'center',
            sortable: true,
            style: 'width:200px',
          },
        ],
        data: [],
        saveData: [],
        itemCds: [],
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      isSave1: false,
      isSave2: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.moc.determination.list.url
      this.saveUrl = transactionConfig.sop.moc.determination.save.url
      // code setting
      this.$comm.getComboItems('MOC_CHECK_TYPE_CD').then(_result => {
        let columns1 = this.$_.filter(_result, { attrVal1: 'equip' })
        let columns2 = this.$_.filter(_result, { attrVal1: 'check-dirve' })

        this.grid.itemCds = this.$_.map(columns1, 'code');
        this.grid2.itemCds = this.$_.map(columns2, 'code');

        if (columns1 && columns1.length > 0) {
          this.$_.forEach(columns1, column => {
            this.grid.columns.push({
              name: column.code,
              field: column.code,
              label: column.codeName,
              align: 'center',
              sortable: true,
              style: 'width:' + String(30 + (column.codeName.length * 10)) + 'px',
              type: 'check',
              true: 'Y',
              false: 'N',
            })
          })
        }
        if (columns2 && columns2.length > 0) {
          this.$_.forEach(columns2, column => {
            this.grid2.columns.push({
              name: column.code,
              field: column.code,
              label: column.codeName,
              align: 'center',
              sortable: true,
              style: 'width:' + String(30 + (column.codeName.length * 10)) + 'px',
              type: 'check',
              true: 'Y',
              false: 'N',
            })
          })
        }
      });
      // list setting
      this.getList('equip');
      this.getList('check-dirve');
    },
    getList(itemType) {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        itemType: itemType
      }
      this.$http.request((_result) => {
        if (_result.data && _result.data.length > 0) {
          if (itemType === 'equip') {
            this.grid.data = _result.data;
          } else {
            this.grid2.data = _result.data;
          }
        }
      },);
    },
    tableDataChange(props, col) {
      if (props.row[col.name + '_edit_flag'] !== 'C') {
        props.row[col.name + '_edit_flag'] = 'U'
      }
    },
    saveEquipChecklist() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.grid.saveData = [];
            this.$_.forEach(this.grid.data, item => {
              for (let key in item) {
                if (this.$_.indexOf(this.grid.itemCds, key) > -1) {
                  this.grid.saveData.push({
                    mocCheckTypeCd: key,  // 체크 구분 코드
                    mocCheckItemCd: item.mocCheckItemCd,  // 체크 항목 코드
                    mocChecklistTypeCd: 'MCT0000001',  // 체크리스트 타입 코드
                    useFlag: item[key],  // 사용 여부
                    regUserId: this.$store.getters.user.userId,  // 등록자 ID
                    chgUserId: this.$store.getters.user.userId,  // 수정자 ID
                    editFlag: item[key + '_edit_flag']
                  })
                }
              }
            })
            this.isSave1 = !this.isSave1
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveEquipChecklistCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList('equip');
    },
    saveCheckDriveChecklist() {
      if (this.$comm.validTable(this.grid2.columns, this.grid2.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.grid2.saveData = [];
            this.$_.forEach(this.grid2.data, item => {
              for (let key in item) {
                if (this.$_.indexOf(this.grid2.itemCds, key) > -1) {
                  this.grid2.saveData.push({
                    mocCheckTypeCd: key,  // 체크 구분 코드
                    mocCheckItemCd: item.mocCheckItemCd,  // 체크 항목 코드
                    mocChecklistTypeCd: 'MCT0000005',  // 체크리스트 타입 코드
                    useFlag: item[key],  // 사용 여부
                    regUserId: this.$store.getters.user.userId,  // 등록자 ID
                    chgUserId: this.$store.getters.user.userId,  // 수정자 ID
                    editFlag: item[key + '_edit_flag']
                  })
                }
              }
            })
            this.isSave2 = !this.isSave2
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCheckDriveChecklistCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList('check-dirve');
    },
  }
};
</script>
